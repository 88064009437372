html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: #585a61;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  margin: 0;
}

h1 {
  font-family: "Poppins", sans-serif;
  color: #5777ba;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  margin-bottom: 16px;
}

h2 {
  font-family: "Raleway", sans-serif;
  color: #515f7d;
  font-size: 20px;
  margin-bottom: 16px;
  text-align: center;
  line-height: 1.2;
}

section {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 60px 0;
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}
